import * as React from 'react'

import Page from '../components/Page'
import SEO from '../components/seo'
import Layout from '../layouts/layout'
import styled from '@emotion/styled'

const HoneypotCatcher = styled.input`
  display: none;
`

const ContactForm = styled.form`
  background: #fff;
  padding: 2em 4em 2em;
  max-width: 400px;
  margin: 50px auto 0;
  box-shadow: 0 0 1em #222;
  border-radius: 2px;
  h2 {
    margin: 0 0 50px 0;
    padding: 0px;
    text-align: center;
    font-size: 30px;
    color: darken(#e5e5e5, 50%);
    border-bottom: solid 1px #e5e5e5;
  }
  p {
    margin: 0 0 3em 0;
    position: relative;
  }
  input:not[name='_gotcha'] {
    display: block;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    margin: 0;
  }
  textarea {
    resize: none;
    overflow: hidden;
  }
  textarea,
  input[type='text'],
  input[type='email'] {
    background: #fff;
    border: 1px solid #dbdbdb;
    font-size: 1em;
    padding: 1em 0.5em;
    border-radius: 2px;
    width: -webkit-fill-available; !important
  }
  input[type='text']:focus,
  input[type='email']:focus {
    background: #fff;
  }
  span {
    display: block;
    background: #f9a5a5;
    padding: 2px 5px;
    color: #666;
  }
  input[type='submit'] {
    background: $button;
    box-shadow: 0 3px 0 0 darken($button, 10%);
    border-radius: 2px;
    border: none;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 2em;
    line-height: 1.6em;
    margin: 2em 0 0;
    outline: none;
    padding: 0.8em 0;
  }
  input[type='submit']:hover {
    background: rgba(61, 61, 61, 0.86);
    text-shadow: 0 1px 3px darken($button, 30%);
  }
  input[type='submit']:hover {
  }
  label {
    position: absolute;
    left: 8px;
    top: 12px;
    color: #999;
    font-size: 16px;
    display: inline-block;
    padding: 0px 10px;
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0);
    @include transition(color 0.3s, top 0.3s, background-color 0.8s);
    &.floatLabel {
      top: -11px;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
    }
  }
`

const SubmitButton = styled.input`
  display: block;
  width: 100%;
  padding: 20px;
  font-family: $font-family;
  -webkit-appearance: none;
  outline: 0;
  border: 0;
  color: white;
  background: $color-primary;
  transition: 0.3s;

  &:hover {
    background: darken($color-primary, 5%);
  }
`

const ContactPage: React.FC = () => {
  let fields = [
    ['Who are you?', 'text', 'name'],
    ['How do I contact you?', 'email', '_replyto']
  ]
  return (
    <Layout>
      <SEO title="Contact" />
      <Page>
        <ContactForm action="https://formspree.io/mvokynpl" method="POST">
          <h2>Contact me</h2>
          {fields.map(f => {
            return (
              <p>
                <label className="floatLabel">{f[0]}</label>
                <input type={f[1]} id={f[2]} name={f[2]}></input>
              </p>
            )
          })}
          <p>
            <label className="floatLabel">Why are you contacting?</label>
            <textarea rows={7} cols={26} id="details" name="details"></textarea>
          </p>
          <HoneypotCatcher type="text" name="_gotcha" />
          <SubmitButton type="submit" value="Send" />
        </ContactForm>
      </Page>
    </Layout>
  )
}

export default ContactPage
